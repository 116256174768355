import React, { useState, useEffect } from "react";
import HomeLayout from "../components/HomeLayout";
import styled from "styled-components";

const EligibilityStyles = styled.main`
  background: #ffffff;
  padding: 30px 0;
  margin-top: -30px;
  min-height: calc(100vh - 312px);

  .blue {
    color: #005f64;
  }

  .red {
    color: #bf624f;
  }

  p {
    font-size: 18px;
  }

  .title {
    color: #005f64;
    padding: 10px;
  }

  .center {
    text-align: center;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    & > * {
      width: 90vw;
      max-width: 1000px;
      margin-bottom: 20px;
    }
  }

  .money {
    color: #005f64;
    font-size: 60px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0;
  }

  .money-subtext {
    margin-top: 0;
    font-size: 14px;
  }

  .bf-card {
    padding: 20px;
    background: #f0f0f0;
    border-radius: 10px;

    &.outline {
      background: white;
      border: 1px solid #005f64;
    }
  }

  button {
    box-sizing: border-box;
    background: #005f64;
    color: white;
    font-size: 20px;
    border: none;
    border-radius: 10000px;
    padding: 15px 20px;
    width: calc(100%);
    text-transform: uppercase;
    font-family: Poppins, sans-serif !important;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .grid-span {
    grid-column: 1 / span 3;
  }

  .eligibility-item {
    margin: 30px 0;
    text-align: left;

    h3 {
      font-weight: normal;
    }

    p {
      font-weight: bold;
      font-size: 24px;
    }
  }

  .next-steps-items {
    grid-column: 2 / span 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;

    > * {
      width: 45%;
      text-align: left;
      position: relative;
      padding-bottom: 20px;

      button.small {
        background: #bf624f;
        font-size: 14px;
        padding: 5px;
        position: absolute;
        bottom: 0px;
      }
    }
  }

  .resource-container {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    overflow-x: scroll;

    > * {
      min-width: 30%;
      margin-right: 10px;
      padding: 10px;
      border: 3px solid #005f64;
      border-radius: 10px;
    }
  }

  @media (max-width: 600px) {
    .eligibility-item {
      h3 {
        font-size: 24px;
      }
      p {
        font-size: 36px;
        font-family: "Open Sans", sans-serif;
      }
    }

    .flex-container {
      padding: 0;

      > * {
        width: 100vw;
      }
    }

    .grid-container {
      grid-template-columns: 1fr;

      > *:not(.full-width) {
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    .grid-span {
      grid-column: 1;
    }

    .next-steps {
      border-radius: 0;
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      max-width: 100vw;
    }

    .next-steps-items {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: scroll;
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 20px;

      > * {
        min-width: 80%;

        &:first-child {
          margin-left: 20px;
        }

        &:last-child {
          margin-right: 20px;
        }

        button.small {
          font-size: 17px;
          width: auto;
          text-transform: none;
          padding: 10px 20px;
          bottom: -10px;
          margin: 0 auto;
          left: 0;
          right: 0;
        }
      }
    }

    .resource-container {
      > * {
        min-width: 80%;
        text-align: left;

        &:first-child {
          margin-left: 10px;
        }
      }
    }
  }
`;

const EligibilityResults = () => {
  const [results, setResults] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      const url = window.location.href;

      const queriesStr = url.split("?")[1];

      if (!queriesStr) {
        setLoading(false);
      }

      const queries = queriesStr.split("&").reduce((acc, keyval) => {
        const [key, value] = keyval.split("=");
        acc[key] = value;
        return acc;
      }, {});

      if (queries.id === undefined) {
        setLoading(false);
      } else {
        getUserResults(queries.id);
      }
    } catch (e) {
      console.error(e);
    }

    async function getUserResults(id) {
      try {
        const url = `https://1k2ee11fsj.execute-api.us-east-2.amazonaws.com/default/fetch_eligibility_results?id=${id}`;

        const results = await fetch(url)
          .then((res) => res.json())
          .then((r) => JSON.parse(r))
          .then((r) => r.body.data.new_eligibility_form_result)
          .catch((e) => console.error(e));

        if (results.length > 0) setResults(results[0]);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  }, []);

  return (
    <HomeLayout>
      <EligibilityStyles>
        <div className="flex-container center">
          <section className="grid-container">
            {loading ? (
              <section>Fetching your results...</section>
            ) : results ? (
              <>
                <div className="grid-span">
                  <h2 className="center blue">Thank you!</h2>
                  <p>
                    Based on your answers, we estimate that you're eligible to
                    receive:
                  </p>
                  <h1 className="center money">
                    ${results.estimated_amount}.00
                  </h1>
                  <p className="money-subtext">per month in resources</p>
                </div>
                <div className="eligibility-item">
                  <h3>Likely eligible for </h3>
                  <p className="blue">SNAP</p>
                </div>
                <div className="eligibility-item">
                  <h3>Might also be eligible for </h3>
                  <p className="blue">Expedited SNAP</p>
                </div>
                <div className="eligibility-item">
                  <h3>Not likely eligible for </h3>
                  <p className="blue">CAPS</p>
                </div>
                <div className="bf-card grid-container grid-span next-steps full-width">
                  <h2 className="red" style={{ alignSelf: "center" }}>
                    Next Steps
                  </h2>
                  <div className="next-steps-items full-width">
                    <div className="next-steps-item">
                      <h3>Manual Application through Georgia Gateway</h3>
                      <p>
                        If you would like to fill out the application on your
                        own, here is a step by step guide to help you.
                      </p>
                      <button className="small">Go to Georgia Gateway</button>
                    </div>
                    <div className="next-steps-item">
                      <h3>Guided Help</h3>
                      <p>
                        Schedule an appointment with BestFit so that we can help
                        you connect to the resources you need.
                      </p>
                      <button className="small">Schedule an appointment</button>
                    </div>
                    <div className="next-steps-item">
                      <h3>Instructions for LIHEAP</h3>
                      <p>
                        Get information on community action agencies and
                        information on how you can apply.
                      </p>
                      <button className="small">Get LIHEAP help</button>
                    </div>
                  </div>
                </div>
                <h2 className="grid-span blue">
                  Some more resources for you to look at
                </h2>
                <div className="grid-span resource-container full-width">
                  <div>
                    <h3>KSU CARE Services</h3>
                    <h4>School Office</h4>
                    <p>
                      Provides support to KSU students who have experiences
                      homelessness, food insecurity...
                    </p>
                  </div>
                  <div>
                    <h3>Food Pantry</h3>
                    <h4>Food Pantry</h4>
                    <p>
                      Provides food to food insecure prople in the Kennesaw
                      area.
                    </p>
                  </div>
                  <div>
                    <h3>Food Pantry</h3>
                    <h4>Food Pantry</h4>
                    <p>
                      Provides food to food insecure prople in the Kennesaw
                      area.
                    </p>
                  </div>
                  <div>
                    <h3>Food Pantry</h3>
                    <h4>Food Pantry</h4>
                    <p>
                      Provides food to food insecure prople in the Kennesaw
                      area.
                    </p>
                  </div>
                  <div>
                    <h3>Food Pantry</h3>
                    <h4>Food Pantry</h4>
                    <p>
                      Provides food to food insecure prople in the Kennesaw
                      area.
                    </p>
                  </div>
                  <div>
                    <h3>Food Pantry</h3>
                    <h4>Food Pantry</h4>
                    <p>
                      Provides food to food insecure prople in the Kennesaw
                      area.
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <p>
                We're sorry, we couldn't find your test results. Please refresh
                the page and try again.
              </p>
            )}
          </section>
        </div>
      </EligibilityStyles>
    </HomeLayout>
  );
};

export default EligibilityResults;
